











































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FileUploader from '@/partials/forms/components/FileUploader.vue'

@Component({
  directives: { mask },
  components: {
    FileUploader,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EventActivityAttachmentForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) activityId!: string
  @Prop() activity!: Record<string, any>

  done = true
  profilePictureURL = ''
  pictureId = ''
  form: Record<string, any> = {
    title: '',
    file: '',
    type: 'article',
    link: '',
    notes: '',
    eventActivityId: '',
    errors: {
      title: '',
      file: '',
      type: '',
      link: '',
      notes: '',
      eventActivityId: '',
      confirm: ''
    }
  }

  attachmentTypes = [
    {
      name: 'article',
      prettyName: 'Artigo'
    },
    {
      name: 'link',
      prettyName: 'Link'
    },
    {
      name: 'book',
      prettyName: 'Livro'
    },
    {
      name: 'video',
      prettyName: 'Vídeo'
    }
  ]

  created () {
    this.form.eventActivityId = this.activityId

    if (process.env.NODE_ENV === 'development') {
      Object.assign(this.form, {
        title: 'Material ABC',
        file: '',
        type: 'article',
        link: 'https://example.com',
        notes: 'my notes'
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      file: '',
      type: '',
      link: '',
      notes: '',
      eventActivityId: '',
      confirm: ''
    }
  }

  submit () {
    this.done = false
    const verb = 'post'
    const route = `/event/${this.eventId}/activity/${this.activityId}/attachment`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess(this.form.title)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess (activityName: string) {
    const successVerb = 'cadastrado'

    const flashMessage = {
      message: `O Material de Apoio ${activityName} foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    if (this.$route.params.speakerId) {
      this.$router.push({ name: 'EventSpeakers', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
    } else {
      this.$router.push({ name: 'EventActivityEdit', params: { eventId: this.eventId, activityId: this.activityId } }, () => this.setFlashMessage(flashMessage))
    }
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
