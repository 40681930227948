











import { Component, Vue } from 'vue-property-decorator'

import EventActivityAttachmentForm from '@/partials/forms/Events/EventActivityAttachmentForm.vue'

@Component({
  components: {
    EventActivityAttachmentForm
  }
})
export default class EventActivityAttachmentCreate extends Vue {

}
